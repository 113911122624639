import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Opportunity } from '@/models/document/opportunity';
import { Customer } from '@/models/document/customer';
import { Contact } from '@/models/document/contact';
import { Policy } from '@/models/document/policy';
import { Document } from '@/models/document/document';
import { getAccessToken } from '@/controllers/common/utils';
import { useAuthenticatedFetch } from '@/controllers/common/hooks/useAuthenticatedFetch';
import {
  getAccountDetails,
  getAssociatedContactList,
  getCategoryList,
  getDocumentsForLinkId,
  getPolicyList,
} from '@/services/document/documentService';
import { getOpportunityList } from '@/services/document/dynamicsService';
import { getDynamicsOpportunityDetails } from '@/services/opportunity/network/dynamics';
import DocumentsForOpportunity from './documents';
import { TableTextInterface } from '@/components/shared/table';
import logger from '@/controllers/logger';

const OpportunityDocuments = () => {
  const { id } = useParams();
  const oppId = id ? id.toLowerCase() : '';
  const { instance } = useMsal();
  const authedFetch = useAuthenticatedFetch();
  const [accessToken, setAccessToken] = useState('');
  const [accountId, setAccountId] = useState<string>('');
  const [userName, setUserName] = useState<string>('');
  const [customer, setCustomer] = useState<Customer>({} as Customer);
  const [associatedContactList, setAssociatedContactList] = useState<Contact[]>(
    []
  );

  const [errorText, setErrorText] = useState<string>();
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [opportunityList, setOpportunityList] = useState<Opportunity[]>([]);
  const [policyList, setPolicyList] = useState<Policy[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);

  const [tableText, setTableText] = useState<TableTextInterface>({
    text: '',
    subText: '',
  });

  const getAccountId = async (oppId: string, accessToken: string) => {
    try {
      const oppDetails = await getDynamicsOpportunityDetails({
        accessToken,
        opportunityId: oppId,
        queryParams: {
          selectFields: 'name,_parentaccountid_value',
          expandFields: '',
        },
      });

      if (!oppDetails) {
        throw new Error('Failed to fetch account id from dynamics.');
      }
      setAccountId(oppDetails._parentaccountid_value);
    } catch (error) {
      console.log('Failed to fetch account id from dynamics.');
    }
  };

  const refreshDocsData = useCallback(async () => {
    setDocumentsLoading(true);
    try {
      if (!oppId) {
        throw new Error('Missing opportunity id');
      }
      if (!accountId) {
        throw new Error('Missing account id');
      }
      const opportunityDocs = await getDocumentsForLinkId(oppId, authedFetch);
      if (!opportunityDocs) {
        throw new Error('Unable to load documents for Opportunity');
      }

      setDocuments(opportunityDocs);
    } catch (error) {
      console.error(error);
      setErrorText(
        'Failed to load documents for opportunity, please try again.'
      );
    }
    setDocumentsLoading(false);
  }, [accountId, authedFetch, oppId]);

  const loadSupportData = useCallback(
    async (accId: string) => {
      const [
        opportunities,
        categories,
        newCustomer,
        newAssociatedContactList,
        newPolicies,
      ] = await Promise.allSettled([
        getOpportunityList(accId, authedFetch),
        getCategoryList(authedFetch),
        getAccountDetails(accId, authedFetch),
        getAssociatedContactList(accId, authedFetch),
        getPolicyList(accId, authedFetch),
      ]);

      if (opportunities.status === 'fulfilled') {
        setOpportunityList(opportunities.value);
      }
      if (categories.status === 'fulfilled') {
        setCategoryList(categories.value);
      }
      if (newCustomer.status === 'fulfilled') {
        setCustomer(newCustomer.value);
      }
      if (newAssociatedContactList.status === 'fulfilled') {
        setAssociatedContactList(newAssociatedContactList.value);
      }
      if (newPolicies.status === 'fulfilled') {
        setPolicyList(newPolicies.value);
      }
    },
    [authedFetch]
  );

  useEffect(() => {
    if (documentsLoading) {
      setTableText({
        text: 'Loading documents...',
        subText: '',
        showSpinner: true,
      });
    } else if (errorText) {
      setTableText({ text: `${errorText}`, subText: '' });
    } else if (documents.length) {
      setTableText({
        text: 'We couldn\'t find a matching document...',
        subText: '',
      });
    } else {
      setTableText({ text: 'Looks empty here...', subText: '' });
    }
  }, [documentsLoading, documents.length, setTableText, errorText]);

  useEffect(() => {
    if (!accessToken) {
      getAccessToken(instance)
        .then((token) => setAccessToken(token))
        .catch((err) => logger.error('failed to get access token', err));
    }

    if (!userName) {
      setUserName(instance.getActiveAccount()?.name || '');
    }
  }, [instance, accessToken, setAccessToken, userName]);

  useEffect(() => {
    if (oppId && accountId) {
      refreshDocsData().catch(logger.error);
    }
  }, [oppId, accountId, authedFetch, refreshDocsData]);

  useEffect(() => {
    if (oppId && accessToken && !accountId) {
      getAccountId(oppId, accessToken).catch(logger.error);
    }
  }, [oppId, authedFetch, accessToken, accountId]);

  useEffect(() => {
    if (accountId) {
      loadSupportData(accountId).catch(logger.error);
    }
  }, [accountId, authedFetch, loadSupportData]);

  return (
    <DocumentsForOpportunity
      documents={documents}
      setDocuments={setDocuments}
      userName={userName}
      customer={customer}
      contactList={associatedContactList}
      categoryList={categoryList}
      opportunityList={opportunityList}
      policyList={policyList}
      refreshData={() => void refreshDocsData()}
      accountId={accountId}
      opportunityId={oppId}
      tableText={tableText}
    />
  );
};

export default OpportunityDocuments;
