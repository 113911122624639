import {
  booleanDropdownOptions,
  constructionTypeOptions,
  dogBreedOptions,
  dwellingTypeOptions,
  dwellingUseOptions,
  heatingTypeOptions,
  HomePriorCarrierEnum,
  homeUpdateOptions,
  monthsDropDownOptions,
  policyTypeOptions,
  roofShapeOptions,
  roofTypeOptions,
  stateCodeOptions,
  swimmingPoolTypesOptions,
  yearsAtAddressOptions,
} from '../helpers';
import type { FormPage } from '../models/formTypes';

export const property: FormPage = {
  id: 'property',
  display: 'Property',
  icon: 'home',
  actions: [
    {
      id: 'quoteRushLink',
      type: 'quoterush',
      fieldName: 'home',
    },
    {
      id: 'ezlynxLink',
      type: 'ezlynx',
      fieldName: 'home',
    },
  ],
  elements: [
    {
      id: 'physical location',
      display: 'Physical location',
      type: 'section',
      actions: [
        {
          id: 'property-address-sync',
          type: 'sync-fields',
          fieldName: '',
          syncFieldsOptions: {
            elementName: 'Physical location',
            actionText: 'Use applicant\'s data',
            underline: true,
            fieldMap: {
              'applicant.currentAddress.lineOne': {
                targetFieldName: 'home.address.lineOne',
              },
              'applicant.currentAddress.lineTwo': {
                targetFieldName: 'home.address.lineTwo',
              },
              'applicant.currentAddress.city': {
                targetFieldName: 'home.address.city',
              },
              'applicant.currentAddress.stateCode': {
                targetFieldName: 'home.address.stateCode',
              },
              'applicant.currentAddress.postalCode': {
                targetFieldName: 'home.address.postalCode',
              },
              'applicant.currentAddress.yearsAtAddress': {
                targetFieldName: 'home.address.yearsAtAddress',
              },
            },
          },
        },
      ],
      elements: [
        {
          name: 'home.address.lineOne',
          display: 'Street address',
          type: 'addressautocomplete',
          placeholder: 'Enter street address...',
          actions: [
            {
              id: 'propertyAddressZillow',
              type: 'zillow',
              fieldName: 'home.address',
            },
            {
              id: 'propertyAddressGoogleMaps',
              type: 'google-maps',
              fieldName: 'home.address',
            },
          ],
          validation: {
            maxLength: {
              value: 255,
              message: 'Street address cannot be more than 255 characters',
            },
          },
        },
        {
          name: 'home.address.lineTwo',
          display: 'Address line 2',
          type: 'text',
          placeholder: 'Enter address line 2...',
          validation: {
            maxLength: {
              value: 50,
              message: 'Address line 2 cannot be more than 50 characters',
            },
          },
        },
        {
          name: 'home.address.city',
          display: 'City',
          type: 'text',
          placeholder: 'Enter city...',
        },
        {
          name: 'home.address.stateCode',
          display: 'State',
          placeholder: 'Select...',
          type: 'dropdown',
          options: stateCodeOptions,
        },
        {
          name: 'home.address.postalCode',
          display: 'ZIP code',
          placeholder: 'Enter ZIP code...',
          type: 'text',
          validation: {
            pattern: {
              value: /^\d{5}(-\d{4})?$/,
              message: 'Please enter a valid ZIP code',
            },
          },
        },
        {
          name: 'home.address.yearsAtAddress',
          display: 'Years at address',
          type: 'dropdown',
          options: yearsAtAddressOptions,
        },
        {
          name: 'home.ratingInfo.isWithinCityLimits',
          display: 'Within city limits',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'purchaseInfo',
      display: 'Purchase info',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.purchaseDate',
          display: 'Purchase date',
          type: 'date',
        },
        {
          name: 'residence.isNewHome',
          display: 'New purchase?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'usage',
      display: 'Usage',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.dwellingUse',
          display: 'Property usage',
          placeholder: 'Select...',
          type: 'dropdown',
          options: dwellingUseOptions,
        },
        {
          name: 'residence.hasRecentlyMoved',
          display: 'Has recently moved',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'generalStructureInfo',
      display: 'General structure info',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.yearBuilt',
          display: 'Year built',
          placeholder: 'YYYY',
          type: 'number',
          validation: {
            pattern: {
              value: /^\d{4}$/,
              message: 'Please enter a valid year',
            },
          },
        },
        {
          name: 'home.ratingInfo.structureType',
          display: 'Structure',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Apartment', display: 'Apartment' },
            { key: 'Backsplit', display: 'Backsplit' },
            { key: 'Bi-Level', display: 'Bi-Level' },
            { key: 'Bi-Level/Row Center', display: 'Bi-Level/Row Center' },
            { key: 'Bi-Level/Row End', display: 'Bi-Level/Row End' },
            { key: 'Bungalow', display: 'Bungalow' },
            { key: 'Cape Cod', display: 'Cape Cod' },
            { key: 'Colonial', display: 'Colonial' },
            { key: 'Condo', display: 'Condo' },
            { key: 'Coop', display: 'Coop' },
            { key: 'Contemporary', display: 'Contemporary' },
            { key: 'Cottage', display: 'Cottage' },
            { key: 'Dwelling', display: 'Dwelling' },
            { key: 'Federal Colonial', display: 'Federal Colonial' },
            { key: 'Mediterranean', display: 'Mediterranean' },
            { key: 'Ornate Victorian', display: 'Ornate Victorian' },
            { key: 'Queen Anne', display: 'Queen Anne' },
            { key: 'Raised Ranch', display: 'Raised Ranch' },
            { key: 'Rambler', display: 'Rambler' },
            { key: 'Ranch', display: 'Ranch' },
            { key: 'Rowhouse', display: 'Rowhouse' },
            { key: 'Rowhouse Center', display: 'Rowhouse Center' },
            { key: 'Rowhouse End', display: 'Rowhouse End' },
            { key: 'Southwest Adobe', display: 'Southwest Adobe' },
            { key: 'Split Foyer', display: 'Split Foyer' },
            { key: 'Split Level', display: 'Split Level' },
            { key: 'Substandard', display: 'Substandard' },
            { key: 'Townhouse', display: 'Townhouse' },
            { key: 'Townhouse Center', display: 'Townhouse Center' },
            { key: 'Townhouse End', display: 'Townhouse End' },
            { key: 'Tri-Level', display: 'Tri-Level' },
            { key: 'Tri-Level Center', display: 'Tri-Level Center' },
            { key: 'Victorian', display: 'Victorian' },
          ],
        },
        {
          name: 'home.ratingInfo.dwellingType',
          display: 'Families',
          placeholder: 'Select...',
          type: 'dropdown',
          options: dwellingTypeOptions,
        },
        {
          name: 'home.ratingInfo.constructionType',
          display: 'Construction',
          placeholder: 'Select...',
          type: 'dropdown',
          options: constructionTypeOptions,
        },
        {
          name: 'home.ratingInfo.isUnderConstruction',
          display: 'Under construction',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.squareFeet',
          display: 'Square feet',
          placeholder: 'Enter square feet',
          type: 'number',
        },
        {
          name: 'home.ratingInfo.numberOfStories',
          display: 'Stories',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: '1', display: '1' },
            { key: '2', display: '2' },
            { key: '3', display: '3' },
            { key: '4', display: '4' },
          ],
        },
        {
          name: 'home.ratingInfo.foundationType',
          display: 'Foundation type',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'SLAB', display: 'Slab' },
            { key: 'OPEN_PIERS', display: 'Open' },
            {
              key: 'TYPE_UNKNOWN_CRAWLSPACE',
              display: 'Crawl Space',
            },
            {
              key: 'PIER',
              display: 'Pier & Grade Beam',
            },
            {
              key: 'TYPE_UNKNOWN_BASEMENT',
              display: 'Basement',
            },
          ],
        },
        {
          name: 'home.ratingInfo.numberOfUnits',
          display: 'Units in building',
          placeholder: 'Enter number of units in building',
          type: 'number',
        },
        {
          name: 'home.ratingInfo.kitchenType',
          display: 'Kitchen type',
          placeholder: 'Select kitchen type',
          type: 'dropdown',
          options: [
            { key: 'BASIC', display: 'Basic' },
            { key: 'BUILDERS_GRADE', display: 'Builder\'s Grade' },
            {
              key: 'SEMI_CUSTOM',
              display: 'Semi Custom',
            },
            {
              key: 'CUSTOM',
              display: 'Custom',
            },
            {
              key: 'DESIGNER',
              display: 'Designer',
            },
          ],
        },
        {
          name: 'home.ratingInfo.kitchenCount',
          display: 'Kitchen count',
          placeholder: 'Enter kitchen count',
          type: 'number',
        },
        {
          name: 'home.ratingInfo.fullBathType',
          display: 'Full bath type',
          placeholder: 'Select full bath type',
          type: 'dropdown',
          options: [
            { key: 'BASIC', display: 'Basic' },
            { key: 'BUILDERS_GRADE', display: 'Builder\'s Grade' },
            {
              key: 'SEMI_CUSTOM',
              display: 'Semi Custom',
            },
            {
              key: 'CUSTOM',
              display: 'Custom',
            },
            {
              key: 'DESIGNER',
              display: 'Designer',
            },
          ],
        },
        {
          name: 'home.ratingInfo.numberOfFullBaths',
          display: 'Number of full bathrooms',
          placeholder: 'Enter full bathroom count',
          type: 'number',
        },
        {
          name: 'home.ratingInfo.halfBathType',
          display: 'Half bath type',
          placeholder: 'Select half bath type',
          type: 'dropdown',
          options: [
            { key: 'BASIC', display: 'Basic' },
            { key: 'BUILDERS_GRADE', display: 'Builder\'s Grade' },
            {
              key: 'SEMI_CUSTOM',
              display: 'Semi Custom',
            },
            {
              key: 'CUSTOM',
              display: 'Custom',
            },
            {
              key: 'DESIGNER',
              display: 'Designer',
            },
          ],
        },
        {
          name: 'home.ratingInfo.numberOfPartialBaths',
          display: 'Number of half bathrooms',
          placeholder: 'Enter half bathroom count',
          type: 'number',
        },
      ],
    },
    {
      id: 'roof',
      display: 'Roof',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.roofShape',
          display: 'Shape',
          placeholder: 'Select...',
          type: 'dropdown',
          options: roofShapeOptions,
        },
        {
          name: 'home.ratingInfo.roofType',
          display: 'Material',
          placeholder: 'Select...',
          type: 'dropdown',
          options: roofTypeOptions,
        },
        {
          name: 'home.ratingInfo.hasSolarPanels',
          display: 'Solar panels',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'heatingAndCooling',
      display: 'Heating & cooling',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.heatingType',
          display: 'Primary heat source',
          placeholder: 'Select...',
          type: 'dropdown',
          options: heatingTypeOptions,
        },
      ],
    },
    {
      id: 'updates',
      display: 'Updates',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.roofingUpdate',
          display: 'Type of roof update',
          placeholder: 'Select...',
          type: 'dropdown',
          options: homeUpdateOptions,
        },
        {
          name: 'home.ratingInfo.roofingUpdateYear',
          display: 'Year roof updated',
          placeholder: 'YYYY',
          type: 'number',
          validation: {
            pattern: {
              value: /^\d{4}$/,
              message: 'Please enter a valid year',
            },
          },
        },
        {
          name: 'home.ratingInfo.electricalUpdate',
          display: 'Type of electrical update',
          placeholder: 'Select...',
          type: 'dropdown',
          options: homeUpdateOptions,
        },
        {
          name: 'home.ratingInfo.electricalUpdateYear',
          display: 'Year electrical updated',
          placeholder: 'YYYY',
          type: 'number',
          validation: {
            pattern: {
              value: /^\d{4}$/,
              message: 'Please enter a valid year',
            },
          },
        },
        {
          name: 'home.ratingInfo.plumbingUpdate',
          display: 'Type of plumbing update',
          placeholder: 'Select...',
          type: 'dropdown',
          options: homeUpdateOptions,
        },
        {
          name: 'home.ratingInfo.plumbingUpdateYear',
          display: 'Year plumbing updated',
          placeholder: 'YYYY',
          type: 'number',
          validation: {
            pattern: {
              value: /^\d{4}$/,
              message: 'Please enter a valid year',
            },
          },
        },
        {
          name: 'home.ratingInfo.heatingUpdate',
          display: 'Type of heating update',
          placeholder: 'Select...',
          type: 'dropdown',
          options: homeUpdateOptions,
        },
        {
          name: 'home.ratingInfo.heatingUpdateYear',
          display: 'Year heating updated',
          placeholder: 'YYYY',
          type: 'number',
          validation: {
            pattern: {
              value: /^\d{4}$/,
              message: 'Please enter a valid year',
            },
          },
        },
        {
          name: 'home.ratingInfo.waterHeaterYear',
          display: 'Water heater year',
          placeholder: 'Enter water heater year',
          type: 'number',
          validation: {
            pattern: {
              value: /^\d{4}$/,
              message: 'Please enter a valid year',
            },
          },
        },
      ],
    },
    {
      id: 'amenities',
      display: 'Amenities',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.hasSwimmingPool',
          display: 'Pool?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.isSwimmingPoolFenced',
          display: 'Is pool fenced?',
          type: 'bool',
          options: booleanDropdownOptions,
          visibleIf: {
            fieldName: 'home.ratingInfo.hasSwimmingPool',
            includeList: [true],
          },
        },
        {
          name: 'home.ratingInfo.swimmingPoolType',
          display: 'Pool type',
          placeholder: 'Select...',
          type: 'dropdown',
          options: swimmingPoolTypesOptions,
          visibleIf: {
            fieldName: 'home.ratingInfo.hasSwimmingPool',
            includeList: [true],
          },
        },
        {
          name: 'home.ratingInfo.hasTrampoline',
          display: 'Trampoline?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'security',
      display: 'Security',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.protectiveDevices.hasSmokeDetector',
          display: 'Smoke detectors?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.protectiveDevices.hasFireAlarm',
          display: 'Fire alarm?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.distanceToFireHydrant',
          display: 'Distance to fire hydrant',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: '1-500', display: '1-500' },
            { key: '501-600', display: '501-600' },
            { key: '601-1000', display: '601-1000' },
            { key: '1001-1100', display: '1001-1100' },
            { key: '1101-1200', display: '1101-1200' },
            { key: '1201-1300', display: '1201-1300' },
            { key: '1301-1400', display: '1301-1400' },
            { key: '1401-1500', display: '1401-1500' },
            { key: '1501 and Greater', display: '1501 and Greater' },
            { key: 'None in Area', display: 'None in Area' },
          ],
        },
        {
          name: 'home.ratingInfo.isWithinFireDistrict',
          display: 'Within fire district?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.distanceToFireStation',
          display: 'Distance to fire station',
          placeholder: 'Enter distance to fire station...',
          type: 'number',
        },
        {
          name: 'home.isGatedCommunity',
          display: 'Gated community',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.protectiveDevices.hasBurglarAlarmLocal',
          display: 'Burglar alarm?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.hasBusinessOnPremises',
          display: 'Business on premises?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.isLimitedAccessCommunity',
          display: 'Protected subdivision?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'animals',
      display: 'Animals',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.hasDogOnPremises',
          display: 'Dog on premises?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.dogBreed',
          display: 'Dog breed?',
          type: 'dropdown',
          options: dogBreedOptions,
        },
        {
          name: 'home.ratingInfo.hasDogBiteHistory',
          display: 'Dog bite history?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.hasExoticAnimalOnPremises',
          display: 'Exotic animal on premises?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.exoticAnimalSpecies',
          display: 'Exotic animal species',
          type: 'text',
          placeholder: 'Enter the exotic animal species...',
          visibleIf: {
            fieldName: 'home.ratingInfo.hasExoticAnimalOnPremises',
            includeList: ['true', true],
          },
          validation: {
            maxLength: {
              value: 50,
              message:
                'Exotic animal species cannot be more than 50 characters',
            },
          },
        },
      ],
    },

    {
      id: 'windMitigation',
      display: 'Wind Mitigation',
      type: 'section',
      elements: [
        {
          name: 'home.ratingInfo.windMitigation.hasWindMitigationForm',
          display: 'Has wind mitigation',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.windMitigation.roofCovering',
          display: 'Roof covering',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Meets FBC 1994', display: 'Meets FBC 1994' },
            { key: 'Meets FBC 2001', display: 'Meets FBC 2001' },
            { key: 'Non-FBC', display: 'Non-FBC' },
            {
              key: 'Reinf Concrete Roof Deck',
              display: 'Reinf Concrete Roof Deck',
            },
            { key: 'Unknown', display: 'Unknown' },
          ],
        },
        {
          name: 'home.ratingInfo.windMitigation.roofDeckAttachment',
          display: 'Roof deck attachment',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Level A', display: 'Level A' },
            { key: 'Level B', display: 'Level B' },
            { key: 'Level C', display: 'Level C' },
            { key: 'Metal Deck', display: 'Metal Deck' },
            {
              key: 'Reinforced Concrete Roof Deck',
              display: 'Reinforced Concrete Roof Deck',
            },
            { key: 'Unknown', display: 'Unknown' },
            { key: 'Wood Deck', display: 'Wood Deck' },
            { key: 'No Attic Access', display: 'No Attic Access' },
          ],
        },
        {
          name: 'home.ratingInfo.windMitigation.roofWallConnection',
          display: 'Roof wall connection',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Clips', display: 'Clips' },
            { key: 'Single Wraps', display: 'Single Wraps' },
            { key: 'Double Wraps', display: 'Double Wraps' },
            { key: 'Structural', display: 'Structural' },
            { key: 'Toe Nails', display: 'Toe Nails' },
            { key: 'No Attic Access', display: 'No Attic Access' },
            { key: 'Not Applicable', display: 'Not Applicable' },
            { key: 'Unknown', display: 'Unknown' },
          ],
        },
        {
          name: 'home.ratingInfo.windMitigation.swr',
          display: 'SWR',
          placeholder: 'Select...',
          type: 'dropdown',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.windMitigation.openingProtection',
          display: 'Opening protection',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Basic', display: 'Basic' },
            { key: 'Hurricane', display: 'Hurricane' },
            { key: 'None', display: 'None' },
          ],
        },
        {
          name: 'home.ratingInfo.windMitigation.a3',
          display: 'A3',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.ratingInfo.windMitigation.terrain',
          display: 'Terrain',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'Terrain B', display: 'Terrain B' },
            { key: 'Terrain C', display: 'Terrain C' },
            { key: 'HVHZ', display: 'HVHZ' },
          ],
        },
        {
          name: 'home.ratingInfo.windMitigation.windSpeedDesign',
          display: 'Wind speed/design',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: '100', display: '100' },
            { key: '110', display: '110' },
            { key: '120', display: '120' },
          ],
        },
        {
          name: 'home.ratingInfo.windMitigation.buildingCode',
          display: 'Building code',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'A', display: 'A' },
            { key: 'B', display: 'B' },
            { key: 'C', display: 'C' },
          ],
        },
        {
          name: 'home.ratingInfo.windMitigation.inspectionCompany',
          display: 'Inspection company',
          type: 'text',
        },
        {
          name: 'home.ratingInfo.windMitigation.inspectorName',
          display: 'Inspector name',
          type: 'text',
        },
        {
          name: 'home.ratingInfo.windMitigation.inspectorNumber',
          display: 'Inspector number',
          type: 'text',
        },
        {
          name: 'home.ratingInfo.windMitigation.inspectionDate',
          display: 'Inspection date',
          type: 'date',
        },
      ],
    },

    {
      id: 'ratingInfo',
      display: 'Rating Info',
      type: 'section',
      elements: [
        {
          name: 'home.isRetirementCommunity',
          display: 'Retirement community?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.hasRetireesCredit',
          display: 'Retirees credit?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.hasMatureDiscount',
          display: 'Mature discount?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'home.isNonSmoker',
          display: 'Non smoker?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'hasClaimHistory',
          display: 'Has claim history?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },

    {
      id: 'policy',
      display: 'Policy',
      type: 'section',
      elements: [
        {
          name: 'home.policy.effectiveDate',
          display: 'Effective date',
          type: 'date',
        },
        {
          name: 'home.policy.term',
          display: 'Term',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [{ key: '12 Month', display: '12 Month' }],
        },
        {
          name: 'home.policy.type',
          display: 'Type',
          placeholder: 'Select...',
          type: 'dropdown',
          options: policyTypeOptions,
        },
      ],
    },
    {
      id: 'priorPolicy',
      display: 'Prior policy',
      type: 'section',
      elements: [
        {
          name: 'home.priorPolicy.hasPriorPolicy',
          display: 'Has existing coverage/policy',
          placeholder: 'Select...',
          type: 'dropdown',
          options: [
            { key: 'hasCurrentPolicy', display: 'Has current policy' },
            { key: 'hasExpiredPolicy', display: 'Policy is expired' },
            { key: 'hasNeverInsured', display: 'Has no prior policy' },
          ],
        },
        {
          name: 'home.priorPolicy.carrier',
          display: 'Carrier',
          placeholder: 'Select...',
          visibleIf: {
            fieldName: 'home.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'dropdown',
          options: HomePriorCarrierEnum,
        },
        {
          name: 'home.priorPolicy.monthsWithCarrier',
          display: 'Years with carrier',
          placeholder: 'Select...',
          visibleIf: {
            fieldName: 'home.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'dropdown',
          options: monthsDropDownOptions,
        },
        {
          name: 'home.priorPolicy.expirationDate',
          display: 'Expiration date',
          visibleIf: {
            fieldName: 'home.priorPolicy.hasPriorPolicy',
            includeList: ['hasCurrentPolicy'],
          },
          type: 'date',
        },
        {
          name: 'home.priorPolicy.reasonNoPrior',
          display: 'Reason no prior',
          placeholder: 'Select...',
          visibleIf: {
            fieldName: 'home.priorPolicy.hasPriorPolicy',
            excludeList: ['hasCurrentPolicy'],
          },
          type: 'dropdown',
          options: [
            { key: 'First Time Homeowner', display: 'First Time Homeowner' },
            { key: 'Has Been Renting', display: 'Has Been Renting' },
            {
              key: 'Prior Ins Through Parent/Spouse/Other',
              display: 'Prior Insurance Through Parent/Spouse/Other',
            },
            {
              key: 'Could Not Obtain Prior Insurance',
              display: 'Could Not Obtain Prior Insurance',
            },
            {
              key: 'Did Not Insure (Self-Insured)',
              display: 'Did Not Insure (Self-Insured)',
            },
          ],
        },
      ],
    },
  ],
};
