import { useEffect, useState } from 'react';
import { getRaterIntegration } from '@/services/opportunity/network/dynamics';
import {
  insuranceProductFromCrmMapping,
  mapRaterIntegrationFromRecordSet,
} from '@/services/opportunity/utils';
import {
  RaterIntegration,
  RaterIntegrationResponse,
} from '../../../models/opportunity/raterIntegration';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '@/controllers/common/utils';
import { RaterProductEnabled } from '@/services/forms/models/rateProductTypes';
import { InsuranceProduct } from '@bwinsurance/meta-rater-types';
import logger from '@/controllers/logger';

export const useRaterIntegration = ({
  loading,
  crmAgentId,
}: {
  loading: boolean;
  crmAgentId?: string;
}) => {
  const { instance } = useMsal();

  const [raterProductEnabled, setRaterProductEnabled] = useState<
    RaterProductEnabled[]
  >([]);

  const loadRaterIntegration = async (agentId: string) => {
    try {
      const accessToken = await getAccessToken(instance);

      if (!accessToken) {
        throw new Error('Failed to get access token');
      }
      const result: RaterIntegrationResponse[] | null =
        await getRaterIntegration(agentId, accessToken);
      const raterIntegrations: RaterIntegration[] = (result || []).map(
        mapRaterIntegrationFromRecordSet
      );

      const newRaterProductEnabled = raterIntegrations.map((item) => {
        return {
          rater: item.rater,
          product: insuranceProductFromCrmMapping[
            item.product
          ] as InsuranceProduct,
        };
      });

      setRaterProductEnabled(newRaterProductEnabled);
    } catch (error) {
      console.log('Failed to load rater integration data', error);
      setRaterProductEnabled([]);
    }
  };

  useEffect(() => {
    if (!loading && crmAgentId) {
      loadRaterIntegration(crmAgentId).catch((cause: unknown) =>
        logger.error(
          'An error occured while attempting to `loadRaterIntegration`.',
          cause
        )
      );
    }
  }, [loading, crmAgentId]);

  return { raterProductEnabled };
};
