import {
  InsuranceProduct,
  InsuranceProductEnum,
  Raters,
  RatersEnum,
} from '@bwinsurance/meta-rater-types';
import { QuoteGroupWithQuote } from '../models/quoteTypes';
import {
  INSURANCE_PRODUCT_TO_CRM_PRODUCT,
  RaterProductEnabled,
} from '../models/rateProductTypes';
import { UseFormGetValues } from 'react-hook-form';
import { AgentIntakeForm } from '../agent/schema';

export const findQuoteGroups = ({
  raterProductToSend,
  quoteGroups,
}: {
  raterProductToSend: RaterProductEnabled[];
  quoteGroups: QuoteGroupWithQuote[];
}) => {
  const successQuoteGroups: QuoteGroupWithQuote[] = [];
  const errorQuoteGroups: QuoteGroupWithQuote[] = [];
  raterProductToSend.forEach((raterProduct) => {
    const group = quoteGroups.find(
      (quoteGroup) =>
        quoteGroup.rater === raterProduct.rater &&
        quoteGroup.insuranceProduct === raterProduct.product
    );
    if (group?.raterData) {
      successQuoteGroups.push(group);
    }
    if (group?.errors) {
      errorQuoteGroups.push(group);
    }
  });

  return { successQuoteGroups, errorQuoteGroups };
};

export const findRaterQuoteGroup = (
  quoteGroups: QuoteGroupWithQuote[],
  rater: Raters,
  product?: InsuranceProduct
) => {
  return quoteGroups.find(
    (quoteGroup) =>
      quoteGroup.rater === rater &&
      (!product || quoteGroup.insuranceProduct === product)
  );
};

export const findLatestQuoteGroup = (quoteArray: QuoteGroupWithQuote[]) => {
  return quoteArray.length
    ? quoteArray.reduce((latest, current) =>
        current.updated > latest.updated ? current : latest
      )
    : undefined;
};

export const getSuccessRaterQuoteGroups = (
  quoteGroups: QuoteGroupWithQuote[]
) => {
  const successRaterQuoteGroups: QuoteGroupWithQuote[] = [];
  const allSuccessEZ: {
    Home: QuoteGroupWithQuote[];
    Auto: QuoteGroupWithQuote[];
  } = {
    Home: [],
    Auto: [],
  };

  const allSuccessQuoteGroups = quoteGroups.filter(
    (quoteGroup) => quoteGroup.status === 'Completed' && quoteGroup.raterData
  );

  allSuccessQuoteGroups.forEach((quoteGroup) => {
    if (quoteGroup.rater === RatersEnum.enum.EZLynx) {
      if (quoteGroup.insuranceProduct === InsuranceProductEnum.enum.Home) {
        allSuccessEZ.Home.push(quoteGroup);
      }
      if (quoteGroup.insuranceProduct === InsuranceProductEnum.enum.Auto) {
        allSuccessEZ.Auto.push(quoteGroup);
      }
    }
  });

  const latestSuccessEZHome = findLatestQuoteGroup(allSuccessEZ.Home);
  const latestSuccessEZAuto = findLatestQuoteGroup(allSuccessEZ.Auto);
  // currently, we only allow one successful submission to QuoteRush
  // there should be at most one successful QuoteRush quote group, if it exists
  const successQR = findRaterQuoteGroup(
    allSuccessQuoteGroups,
    RatersEnum.enum.QuoteRush
  );

  if (successQR) {
    successRaterQuoteGroups.push(successQR);
  }
  if (latestSuccessEZHome) {
    successRaterQuoteGroups.push(latestSuccessEZHome);
  }
  if (latestSuccessEZAuto) {
    successRaterQuoteGroups.push(latestSuccessEZAuto);
  }

  return successRaterQuoteGroups;
};

export const getSuccessMessage = (quoteGroups: QuoteGroupWithQuote[]) => {
  const isQRQuoteGroup = findRaterQuoteGroup(
    quoteGroups,
    RatersEnum.enum.QuoteRush
  );
  const isEZHomeQuoteGroup = findRaterQuoteGroup(
    quoteGroups,
    RatersEnum.enum.EZLynx,
    InsuranceProductEnum.enum.Home
  );
  const isEZAutoQuoteGroup = findRaterQuoteGroup(
    quoteGroups,
    RatersEnum.enum.EZLynx,
    InsuranceProductEnum.enum.Auto
  );

  const crmHomeDisplay =
    INSURANCE_PRODUCT_TO_CRM_PRODUCT[InsuranceProductEnum.enum.Home];
  const crmAutoDisplay =
    INSURANCE_PRODUCT_TO_CRM_PRODUCT[InsuranceProductEnum.enum.Auto];

  let message = '';
  if (isQRQuoteGroup) {
    const messageForOR = isQRQuoteGroup.raterData?.leadId
      ? `QuoteRUSH (Lead ID: ${isQRQuoteGroup.raterData.leadId})`
      : 'QuoteRUSH';

    // QR home only - Homeowners sent to QR (Lead ID)
    if (!isEZHomeQuoteGroup && !isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR}.`;
    }
    // QR & EZ home only - Homeowners sent to QR (Lead ID ) & EZ
    if (isEZHomeQuoteGroup && !isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR} and ${RatersEnum.enum.EZLynx}.`;
    }
    // QR home & EZ auto - Homeowners sent to QR (Lead ID) and Private Passenger Auto Sent to EZ
    if (!isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR} and ${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
    // QR & EZ home & EZ auto - Homeowners sent to QR (Lead ID ) & EZ and Private Passenger Auto Sent to EZ
    if (isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${messageForOR} and ${RatersEnum.enum.EZLynx} and ${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
  }

  if (!isQRQuoteGroup) {
    // EZ home only - Homeowner sent to EZ
    if (isEZHomeQuoteGroup && !isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
    // EZ auto only - Private Passenger Auto Sent to EZ
    if (!isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
    // EZ home & auto only - Homeowners & Private Passenger Auto sent to EZ
    if (isEZHomeQuoteGroup && isEZAutoQuoteGroup) {
      message = `${crmHomeDisplay} and ${crmAutoDisplay} sent to ${RatersEnum.enum.EZLynx}.`;
    }
  }

  return message;
};

export const getErrorMessages = (quoteGroups: QuoteGroupWithQuote[]) => {
  const failedGroups = quoteGroups.map((quoteGroup) => {
    const raterName =
      quoteGroup.rater === RatersEnum.enum.QuoteRush
        ? 'QuoteRUSH'
        : quoteGroup.rater;
    return {
      rater: raterName,
      product: quoteGroup.insuranceProduct,
      errorName: quoteGroup.errors?.[0]?.name,
      errorMessage: quoteGroup.errors?.[0]?.message,
    };
  });

  const uniqueErrorNames = Array.from(
    new Set(failedGroups.map((group) => group.errorName as ErrorType))
  );

  const messages = uniqueErrorNames.map((errorName) => {
    const matchedGroups = failedGroups.filter(
      (group) => group.errorName === errorName
    );
    const raterNames = Array.from(
      new Set(matchedGroups.map((group) => group.rater))
    );
    const failureMessage = matchedGroups
      .map((group) => group.errorMessage)
      .join(' ');
    return getErrorMessage(errorName, {
      quoteGroupNames: raterNames,
      failureMessage: failureMessage,
    });
  });

  return messages;
};

export type ErrorType =
  | 'AuthenticationError'
  | 'ValidationError'
  | 'UnhandledError'
  | 'AlreadySentError'
  | 'InvalidFields';

export const getErrorMessage = (
  errorName?: ErrorType,
  options: {
    quoteGroupNames?: string[];
    failureMessage?: string;
    leadId?: string;
  } = {}
) => {
  const { leadId, quoteGroupNames, failureMessage } = options;
  const leadIdString = leadId ? ` (Lead ID: ${leadId})` : '';
  const nameString = quoteGroupNames?.join(' and ') || '';

  switch (errorName) {
    case 'AuthenticationError':
      return `The login credentials we have for ${nameString} are incorrect. Please contact Agency Support.`;
    case 'UnhandledError':
      return quoteGroupNames && quoteGroupNames.length > 1
        ? `${nameString} are having technical difficulties. Try again.`
        : `${nameString} is having technical difficulties. Try again.`;
    case 'ValidationError':
      return failureMessage || 'Something went wrong. Please try again!';
    case 'AlreadySentError':
      return `You've already sent Homeowners. Please make adjustments directly in ${nameString}${leadIdString}.`;
    case 'InvalidFields':
      return 'Some fields are invalid. Please correct the errors and try again.';
    default:
      return 'Something went wrong. Please try again!';
  }
};

export const getRaterButtonErrorMessage = (
  agentName?: string,
  agentId?: string,
  userId?: string
): string => {
  if (!agentId) {
    return 'Please assign an agent to this opportunity and refresh the page in order to send data to a rater.';
  }

  if (agentId !== userId) {
    const assignedAgentName = agentName ?? 'The agent assigned';
    return `${assignedAgentName} hasn't been set up to send data to a rater. Please contact Agency Support.`;
  }

  return "You haven't been set up to send data to a rater. Please contact Agency Support.";
};

export const flatten = (obj: object) => {
  const result: Record<string, string> = {};
  for (const key of Object.keys(obj)) {
    const item = obj[key as keyof object];
    if (typeof item === 'object' && item !== null) {
      const nested = flatten(item);
      for (const nestedKey of Object.keys(nested)) {
        result[`${key}.${nestedKey}`] = nested[nestedKey];
      }
    } else {
      result[key] = item;
    }
  }
  return result;
};

export const checkRequiredFieldsForRaters = (
  getValues: UseFormGetValues<AgentIntakeForm>,
  raterProductToSend: any
): string[] => {
  const isHomeProduct = raterProductToSend.some(
    (raterProduct: any) =>
      raterProduct.product === 'Home' &&
      raterProduct.rater === RatersEnum.enum.QuoteRush
  );

  const baseRequiredFields = {
    'applicant.firstName': 'first name',
    'applicant.lastName': 'last name',
    'applicant.currentAddress.lineOne': 'street address',
    'applicant.currentAddress.stateCode': 'state',
  };

  const propertyRequiredFields = {
    'home.address.lineOne': 'property street address',
    'home.address.stateCode': 'property state',
  };

  const getMissingFields = (fields: Record<string, string>) => {
    return Object.entries(fields)
      .filter(([key]) => {
        const value = getValues(key as keyof AgentIntakeForm);
        return (
          typeof value === 'undefined' ||
          (typeof value === 'string' && !value.trim())
        );
      })
      .map(([, value]) => value);
  };

  const missingFields = getMissingFields(baseRequiredFields);

  if (isHomeProduct) {
    missingFields.push(...getMissingFields(propertyRequiredFields));
  }

  return missingFields;
};

export const getMissingFieldsStringForRaters = (
  missingFields: string[]
): string => {
  const hasMissing = (fields: string[]) =>
    missingFields.some((field) => fields.includes(field));

  const nameFields = ['first name', 'last name'];
  const addressFields = ['street address', 'state'];
  const propertyAddressFields = ['property street address', 'property state'];

  const missingParts: string[] = [];

  if (hasMissing(nameFields)) missingParts.push("applicant's name");
  if (hasMissing(addressFields))
    missingParts.push("applicant's current address");
  if (hasMissing(propertyAddressFields))
    missingParts.push("property's current address");

  return missingParts.join(' and ');
};

type DirtyFields = Record<string, any>;

export const getChangedFields = (
  data: Record<string, any>,
  dirtyFields: DirtyFields
) => {
  const result: Record<string, any> = {};

  Object.keys(dirtyFields).forEach((key) => {
    if (typeof dirtyFields[key] === 'object' && dirtyFields[key] !== null) {
      const nestedChanged = getChangedFields(data[key], dirtyFields[key]);
      if (Object.keys(nestedChanged).length > 0) {
        result[key] = nestedChanged;
      }
    } else if (dirtyFields[key]) {
      result[key] = data[key];
    }
  });

  return result;
};

export const getCurrentFormattedDateTime = () => {
  const now = new Date();
  const formattedDate = now.toLocaleString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  return formattedDate;
};
