import { useRef, useState } from 'react';
import Button from '../shared/button';
import Search from './search';
import AutoPull from '@/components/documents/dialogs/autoPull';
import { Policy } from '../../models/document/policy';
import CarrierButton from '../shared/carrierButton';
import Tooltip from '../shared/tooltip';
import styles from './controlBar.module.css';
import { validateFile } from '@/controllers/document/utils';

export interface ControlBarProps {
  policyList: Policy[];
  categoryList: string[];
  showAutoPullMessage: any;
  setUploadFiles: any;
  showUploadDialog: any;
  search: string;
  setSearch: any;
  autoPullDisabled: boolean;
  refreshData: () => void;
  showAlertToast: (type: string, message: string) => void;
  forOppDocsView?: boolean;
}

export default function ControlBar({
  policyList,
  categoryList,
  showAutoPullMessage,
  setUploadFiles,
  showUploadDialog,
  search,
  setSearch,
  autoPullDisabled,
  refreshData,
  showAlertToast,
  forOppDocsView = false,
}: ControlBarProps) {
  const fileInputRef: { current: any } = useRef(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const showFilePicker = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  const handleFileSelect = (event: any) => {
    const selectedFile = event.target.files;
    const { isInvalid, message } = validateFile(selectedFile[0]);
    if (isInvalid) {
      showAlertToast('warning', message);
      return;
    }
    setUploadFiles(selectedFile);
    showUploadDialog();
  };

  return (
    <>
      <div className={styles.controlBar}>
        <Search
          search={(event: any) => setSearch(event.target.value)}
          searchValue={search}
        />
        <div className={styles.buttonContainer}>
          {!forOppDocsView && (
            <Tooltip
              message={`We can't automatically get the latest documents for this customer.
Please download them from the carrier and upload them here.`}
              disabled={!autoPullDisabled}
              position={'auto'}
              theme={'crm'}
            >
              <Button
                callback={() => setDialogOpen(true)}
                type={'primary'}
                theme={'crm'}
                icon={'sparkle'}
                disabled={autoPullDisabled}
              >
                Get the latest
              </Button>
            </Tooltip>
          )}
          {!forOppDocsView && (
            <CarrierButton
              type={'secondary'}
              icon={'open'}
              policyList={policyList}
            >
              Go to carrier
            </CarrierButton>
          )}
          <Tooltip
            message={`You can also drag
documents into Fusion.`}
            position={'auto'}
            theme={'crm'}
          >
            <Button
              callback={showFilePicker}
              type={'secondary'}
              theme={'crm'}
              icon={'arrow_upload'}
            >
              Upload
            </Button>
          </Tooltip>
          <input
            data-testid={'file-input'}
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*, video/*, .doc, .docx, .pdf, .xml, .txt, .csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>
      </div>
      <AutoPull
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        showAutoPullMessage={showAutoPullMessage}
        policyList={policyList}
        refreshData={refreshData}
      />
    </>
  );
}
